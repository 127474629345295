import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import { Button, Col, FormFeedback, Input, Label, Row } from "reactstrap";
import { Contact } from "./Contact/contact.type";
import { useState } from "react";
import { useStoreActions } from "../Store/hooks";
import { Helmet } from "react-helmet";
export const ContactMe = () => {
  const postContact = useStoreActions((action) => action.contactMe.postContact);

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const [clicked, setclicked] = useState(false);

  const values: Contact = {
    name: name,
    email: email,
    subject: subject,
    message: message,
  };

  const resetValues = () => {
    setname("");
    setemail("");
    setsubject("");
    setmessage("");
  };

  const postComments = () => {
    postContact(values);
    setTimeout(function () {
      resetValues();
    }, 1500);
  };
  const validateEmail = (email: string) => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    }
    return false;
  };
  const timeout = () => {
    setTimeout(function () {
      setclicked(false);
    }, 1500);
  };
  return (
    <div className="mt-5 p-4">
      <Helmet>
        <title>Hi, Welcome to my site.</title>
        <meta name="description" content="My Site" />
        <meta
          name="keywords"
          content="Sanskar, sanskar, sanskarbajaj, sanskar bajaj, sanskaar, bajajsanskar, Sanskar bajaj, Sanskar Bajaj, SANSKAR, SANSKAR BAJAJ, SANSKARBAJAJ, BAJAJSANSKAR, BAJAJ SANSKAR, sanskar's site, sanskar website, sanskar bajaj website, sanskar site, sanskarsite, sanskar folio, sanskarbajaj portfolio, sanskar bajaj folio, sanskar bajaj website, sanskar bajaj site, sanskar bajaj portfolio, sanskarbajaj.com, sanskar bajaj.com, sanskar personal website, sanskar personal folio, sanskar personal portfolio, bajaj sanskar site, bajaj sanskar website, bajajsanskar site, bajajsanskarsite, sanskarbajajsite, sanskarbajaj site "
        />
      </Helmet>
      <div className="mb-5">
        <h2 className="monstb">Keep in Touch</h2>
        <p className="gray monst">Speak to me if you have any questions.</p>
      </div>
      <br></br> <br></br>
      <div className="row">
        <div className="col-md-2" />
        <div className="col-md-3">
          <div className="service-list text-center">
            <div className="icon">
              <WatchLaterOutlinedIcon fontSize="large" />{" "}
              <ModeCommentOutlinedIcon fontSize="large" />
            </div>
            <div className="service-info">
              <h4>Contact Me</h4>
              <p>
                Feel free to talk to me about anything that bothers you. If you
                need a helping hand, please don't hesitate to contact me. Got a
                question on the services that I provide? I am just a click away.
              </p>
              <p>I live in the India if this helps.</p>
            </div>
          </div>
        </div>
        <div className="col-md-5 ">
          <div className="contact-wrap">
            <h3 className="text-left m-top-0 profdiv">Say Hi to Me</h3>
          </div>
          <Row className="mb-1">
            <Col className="profdiv">
              <Label>
                Email<span className="text-danger">*</span>
              </Label>
              <Input
                type="email"
                placeholder="Enter your email id"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                valid={validateEmail(email)}
                invalid={!validateEmail(email) && email !== ""}
              ></Input>
              <FormFeedback valid>This is a valid email</FormFeedback>
              <FormFeedback>This is not a valid email</FormFeedback>
            </Col>
            <Col className="profdiv">
              <Label>
                Name<span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="Enter your name"
                value={name}
                valid={name !== "" && name.length > 2}
                onChange={(e) => setname(e.target.value)}
              ></Input>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col className="profdiv">
              <Label>Subject</Label>
              <Input
                placeholder="Enter your subject"
                value={subject}
                valid={subject !== ""}
                onChange={(e) => setsubject(e.target.value)}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col className="profdiv">
              <Label>
                Message<span className="text-danger">*</span>
              </Label>
              <Input
                type="textarea"
                rows="5"
                valid={message !== ""}
                placeholder="Enter your message"
                value={message}
                onChange={(e) => setmessage(e.target.value)}
              ></Input>
            </Col>
          </Row>
          <Button
            className="mt-5 mb-5 px-5 py-2 btn-hvr btn "
            disabled={
              email === "" ||
              !validateEmail(email) ||
              name === "" ||
              message === ""
            }
            onClick={() => {
              setclicked(true);
              postComments();
              timeout();
            }}
            title="Enter valid email and other fields to continue"
          >
            Submit
          </Button>
          <div className="text-warning" style={{ textAlign: "center" }}>
            {clicked && "Form Submitted Successfully"}
          </div>
          <div className="col-md-2" />
        </div>
      </div>
    </div>
  );
};
