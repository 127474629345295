import { IconGroup } from "./IconGroup";
import { ScrollToTop } from "./ScrollToTop";
import "./Components.scss";

export const Footer = () => {
  return (
    <div
      className="footer trans"
      style={{
        backgroundColor: "black",
        color: "white",
        textAlign: "center",
        width: "100%",
        padding: "2rem",
      }}
    >
      <IconGroup></IconGroup>
      <p className="mt-3 trans">Copyright &copy; 2022. Sanskar Bajaj</p>
      <p>All Rights Reserved.</p>
      <div style={{ textAlign: "right" }}>
        <ScrollToTop></ScrollToTop>
      </div>
    </div>
  );
};
