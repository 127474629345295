import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { Card, CardImg, Col, Row } from "reactstrap";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
export const Cards = (props: any) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        containerClassName="m-2 "
      >
        <div onMouseOver={handleClick} onMouseOut={handleClick}>
          <Card
            className="shadow-lg p-3 bg-white rounded"
            style={{ maxWidth: "7rem", maxHeight: "7rem" }}
          >
            <CardImg src={props.pic} alt="Card image cap" />
          </Card>
        </div>

        <div onMouseOver={handleClick} onMouseOut={handleClick}>
          <Card
            className="shadow-lg  bg-white rounded"
            style={{ maxWidth: "7rem", maxHeight: "7rem" }}
          >
            <p>
              <strong>LEVEL</strong>
            </p>
            <Row>
              <Col>
                <StarIcon fontSize="small" style={{ color: "gold" }}></StarIcon>
                <StarIcon fontSize="small" style={{ color: "gold" }}></StarIcon>
                <StarIcon fontSize="small" style={{ color: "gold" }}></StarIcon>
                <StarIcon fontSize="small" style={{ color: "gold" }}></StarIcon>
                <StarHalfIcon
                  fontSize="small"
                  style={{ color: "gold" }}
                ></StarHalfIcon>
              </Col>
            </Row>
            <br></br>
            <p>
              <strong>{props.rating}/5</strong>
            </p>
          </Card>
        </div>
      </ReactCardFlip>
    </div>
  );
};
