import { useEffect } from "react";
import { Typewriter } from "react-simple-typewriter";
import { Spinner } from "reactstrap";
import { IconGroup } from "../Components/IconGroup";
import { useStoreActions, useStoreState } from "../Store/hooks";
import "./Pages.scss";
import { Helmet } from "react-helmet";
export const HomePage = () => {
  const getName = useStoreActions((action) => action.contactMe.getName);
  const name = useStoreState((state) => state.contactMe.name);
  const getHobbies = useStoreActions((action) => action.contactMe.getHobbies);
  const hobbies = useStoreState((s) => s.contactMe.hobbies);
  useEffect(() => {
    getName();
    getHobbies();
  }, [getHobbies, getName]);

  return (
    <div className="p-4">
      {
        <div>
          <Helmet>
            <title>Hi, Welcome to my site.</title>
            <meta name="description" content="My Site" />
            <meta
              name="keywords"
              content="Sanskar, sanskar, sanskarbajaj, sanskar bajaj, sanskaar, bajajsanskar, Sanskar bajaj, Sanskar Bajaj, SANSKAR, SANSKAR BAJAJ, SANSKARBAJAJ, BAJAJSANSKAR, BAJAJ SANSKAR, sanskar's site, sanskar website, sanskar bajaj website, sanskar site, sanskarsite, sanskar folio, sanskarbajaj portfolio, sanskar bajaj folio, sanskar bajaj website, sanskar bajaj site, sanskar bajaj portfolio, sanskarbajaj.com, sanskar bajaj.com, sanskar personal website, sanskar personal folio, sanskar personal portfolio, bajaj sanskar site, bajaj sanskar website, bajajsanskar site, bajajsanskarsite, sanskarbajajsite, sanskarbajaj site "
            />
          </Helmet>
          <div className="centered ">
            <h3>Hi, I am </h3>
            <h1 className="text-uppercase name">Sanskar Bajaj
            </h1>
            <span className="h3"> I am a </span>
            <strong className="h2 text-warning">
              <Typewriter
                loop
                cursor
                cursorStyle="<-"
                typeSpeed={100}
                deleteSpeed={50}
                delaySpeed={2500}
                words={
                  hobbies.length > 0
                    ? hobbies
                    : [
                        "Software Engineer",
                        "Problem Solver",
                        "Beardo",
                        "Traveller",
                      ]
                }
              />
            </strong>
            <IconGroup></IconGroup>
            <div className="mt-5 centered-chv ">
              <div className="chevron container"></div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
