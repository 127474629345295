import "../Pages/Pages.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import InstagramIcon from "@mui/icons-material/Instagram";
import "../Components/Components.scss";

export const IconGroup = () => {
  return (
    <div className="mt-4">
      <a
        href="https://www.facebook.com/sanskar.bajaj"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "white" }}
      >
        <FacebookIcon fontSize="large" className="hover-dblue"></FacebookIcon>{" "}
        &nbsp; &nbsp;
      </a>
      <a
        href="https://mobile.twitter.com/sanskarbajaj2"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "white" }}
      >
        <TwitterIcon fontSize="large" className="hover-blue"></TwitterIcon>{" "}
        &nbsp; &nbsp;
      </a>
      <a
        href="https://www.linkedin.com/in/sanskar-bajaj-68b94216b/"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "white" }}
      >
        <LinkedInIcon fontSize="large" className="hover-lblue"></LinkedInIcon>{" "}
        &nbsp; &nbsp;
      </a>
      <a
        href="https://github.com/Sanskarbajaj"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "white" }}
      >
        <GitHubIcon fontSize="large" className="hover-black"></GitHubIcon>{" "}
        &nbsp; &nbsp;
      </a>
      <a
        href="https://www.instagram.com/sanskarbajaj/"
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "white" }}
      >
        <InstagramIcon fontSize="large" className="hover-pink"></InstagramIcon>
      </a>
    </div>
  );
};
