import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import "react-vertical-timeline-component/style.min.css";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";

export const Experience = () => {
  return (
    <div className="mb-5 p-4">
      <Helmet>
        <title>Hi, Welcome to my site.</title>
        <meta name="description" content="My Site" />
        <meta
          name="keywords"
          content="Sanskar, sanskar, sanskarbajaj, sanskar bajaj, sanskaar, bajajsanskar, Sanskar bajaj, Sanskar Bajaj, SANSKAR, SANSKAR BAJAJ, SANSKARBAJAJ, BAJAJSANSKAR, BAJAJ SANSKAR, sanskar's site, sanskar website, sanskar bajaj website, sanskar site, sanskarsite, sanskar folio, sanskarbajaj portfolio, sanskar bajaj folio, sanskar bajaj website, sanskar bajaj site, sanskar bajaj portfolio, sanskarbajaj.com, sanskar bajaj.com, sanskar personal website, sanskar personal folio, sanskar personal portfolio, bajaj sanskar site, bajaj sanskar website, bajajsanskar site, bajajsanskarsite, sanskarbajajsite, sanskarbajaj site "
        />
      </Helmet>
      <Container className="border">
        <h3 className="monstb mt-5 underline">EXPERIENCE AND EDUCATION</h3>
        <h5 className="monst mb-5 text-gray underline">
          The stuff i'm made of
        </h5>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="June 2021 - Present"
            contentStyle={{
              background: "white",
              color: "#000",
              borderTop: "5px solid #2196f3",
            }}
            contentArrowStyle={{ borderRight: "7px solid  white" }}
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<WorkIcon></WorkIcon>}
          >
            <h3 className="vertical-timeline-element-title">
              Full Stack Developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Société Générale GSC, Banglore (Karnataka)
            </h4>
            <p>French Multi National Bank</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Jan 2021 - March 2021"
            contentStyle={{
              background: "white",
              color: "#000",
              borderTop: "5px solid #2196f3",
            }}
            contentArrowStyle={{ borderRight: "7px solid  white" }}
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<WorkIcon></WorkIcon>}
          >
            <h3 className="vertical-timeline-element-title">
              Software Engineer Trainee
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Violet Automation, Panchkula (Haryana)
            </h4>
            <p>IT Startup</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="July 2017-July 2021"
            contentStyle={{
              background: "white",
              color: "#000",
              borderTop: "5px solid #e91e63",
            }}
            contentArrowStyle={{ borderRight: "7px solid  white" }}
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            icon={<SchoolIcon></SchoolIcon>}
          >
            <h3 className="vertical-timeline-element-title">
              Bachelors in Computer Science and Engineering
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Bachelor Degree
            </h4>
            <p>8.3 GPA</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="May 2016-May 2017"
            contentStyle={{
              background: "white",
              color: "#000",
              borderTop: "5px solid #e91e63",
            }}
            contentArrowStyle={{ borderRight: "7px solid  white" }}
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            icon={<SchoolIcon></SchoolIcon>}
          >
            <h3 className="vertical-timeline-element-title">Class XII</h3>
            <h4 className="vertical-timeline-element-subtitle">C.B.S.E</h4>
            <p>80.8%</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="March 2014- March 2015"
            contentStyle={{
              background: "white",
              color: "#000",
              borderTop: "5px solid #e91e63",
            }}
            contentArrowStyle={{ borderRight: "7px solid  white" }}
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            icon={<SchoolIcon></SchoolIcon>}
          >
            <h3 className="vertical-timeline-element-title">Class X</h3>
            <h4 className="vertical-timeline-element-subtitle">C.B.S.E</h4>
            <p>8.6 CGPA</p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Container>
    </div>
  );
};
