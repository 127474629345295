import {
  Col,
  Container,
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Cards } from "../Components/Cards";
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import java from "../assets/java.png";
import mysql from "../assets/mysql.png";
import jenkins from "../assets/jenkins.png";
import typescript from "../assets/typesc.png";
import postgres from "../assets/postgresSql.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import springBoot from "../assets/SpringBoot1.png";
import vscode from "../assets/vscode.png";
import easyPeasy from "../assets/easyPeasy.png";
import git from "../assets/git.png";
import intellij from "../assets/intellij.png";
import jpaHibernate from "../assets/JpaAndHibernate.png";
import think from "../assets/think.jpeg";
import { useState } from "react";
import { Helmet } from "react-helmet";

export const Technologies = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Helmet>
        <title>Hi, Welcome to my site.</title>
        <meta name="description" content="My Site" />
        <meta
          name="keywords"
          content="Sanskar, sanskar, sanskarbajaj, sanskar bajaj, sanskaar, bajajsanskar, Sanskar bajaj, Sanskar Bajaj, SANSKAR, SANSKAR BAJAJ, SANSKARBAJAJ, BAJAJSANSKAR, BAJAJ SANSKAR, sanskar's site, sanskar website, sanskar bajaj website, sanskar site, sanskarsite, sanskar folio, sanskarbajaj portfolio, sanskar bajaj folio, sanskar bajaj website, sanskar bajaj site, sanskar bajaj portfolio, sanskarbajaj.com, sanskar bajaj.com, sanskar personal website, sanskar personal folio, sanskar personal portfolio, bajaj sanskar site, bajaj sanskar website, bajajsanskar site, bajajsanskarsite, sanskarbajajsite, sanskarbajaj site "
        />
      </Helmet>
      <Container className="p-4">
        <h2 className="monstb ">Technologies and Tools</h2>
        <h3 className="monstb text-gray underline  mb-5 mt-5">Tech Stack</h3>
        <Row>
          <Col lg="2"></Col>
          <Col lg="2">
            <Cards pic={html} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={css} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={javascript} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={react} rating="4.5"></Cards>
          </Col>
        </Row>
        <Row>
          <Col lg="2"></Col>

          <Col lg="2">
            <Cards pic={easyPeasy} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={java} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={postgres} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={mysql} rating="4.5"></Cards>
          </Col>
        </Row>
        <Row>
          <Col lg="2"></Col>

          <Col lg="2">
            <Cards pic={typescript} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={springBoot} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={git} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={jpaHibernate} rating="4.5"></Cards>
          </Col>
        </Row>

        <Row>
          <Col lg="2"></Col>

          <Col lg="2">
            <Cards pic={intellij} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={vscode} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={redux} rating="4.5"></Cards>
          </Col>
          <Col lg="2">
            <Cards pic={jenkins} rating="4.5"></Cards>
          </Col>
        </Row>
      </Container>
      <div style={{ textAlign: "left" }}>
        <img
          src={think}
          alt="think"
          width="200px"
          height="200px"
          onClick={() => setIsOpen(true)}
          className="imghvr"
        />
      </div>
      <Modal
        funk={true}
        isOpen={isOpen}
        backdrop={true}
        toggle={() => setIsOpen(!isOpen)}
        centered={true}
        size="lg"
      >
        <ModalHeader>
          Character , Creative Thinking and Brain matters. <br />
          Skills can be taught.
        </ModalHeader>
        <ModalFooter>
          {" "}
          <strong>~ SANSKAR BAJAJ ~</strong>
        </ModalFooter>
      </Modal>
    </div>
  );
};
