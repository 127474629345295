import React from "react";
import { Helmet } from "react-helmet";
export const Anonymous = () => {
  return (
    <div className="p-4">
      <Helmet>
        <title>Hi, Welcome to my site.</title>
        <meta name="description" content="My Site" />
        <meta
          name="keywords"
          content="Sanskar, sanskar, sanskarbajaj, sanskar bajaj, sanskaar, bajajsanskar, Sanskar bajaj, Sanskar Bajaj, SANSKAR, SANSKAR BAJAJ, SANSKARBAJAJ, BAJAJSANSKAR, BAJAJ SANSKAR, sanskar's site, sanskar website, sanskar bajaj website, sanskar site, sanskarsite, sanskar folio, sanskarbajaj portfolio, sanskar bajaj folio, sanskar bajaj website, sanskar bajaj site, sanskar bajaj portfolio, sanskarbajaj.com, sanskar bajaj.com, sanskar personal website, sanskar personal folio, sanskar personal portfolio, bajaj sanskar site, bajaj sanskar website, bajajsanskar site, bajajsanskarsite, sanskarbajajsite, sanskarbajaj site "
        />
      </Helmet>

      <h1 className="monst veni fadeInLeft">VENI VIDI VICI </h1>
    </div>
  );
};
