import { Contact } from "./contact.type";
import {action,Action,Thunk,thunk} from 'easy-peasy';
import axios from "axios";

export interface ContactMeModel{
contact:Contact;
hobbies:string[];
name:string;
    postContact:Thunk<ContactMeModel,Contact>;
    setDetails:Action<ContactMeModel,Contact>;
    getHobbies:Thunk<ContactMeModel>;
    getName:Thunk<ContactMeModel>;
    setHobbies:Action<ContactMeModel,string[]>
    setName:Action<ContactMeModel,string>;
}

export const contactMe:ContactMeModel={
    hobbies:[],
    contact:{
        id:-1,
        name:'',
        email:'',
        subject:'',
        message:'',
    },
    name:''
    ,
    setName:action((state,name)=>{
state.name=name;
    }),
    setHobbies:action((state,hobbies)=>{
state.hobbies=hobbies;
    }),
    getHobbies:thunk(async(actions)=>{
        try{
            const {data}= await axios.get("https://my-site-backend-java.herokuapp.com/get-hobbies");
            actions.setHobbies(data);
           }catch(error){
       }
    }),
    getName:thunk(async(actions)=>{
        try{
            const {data}= await axios.get("https://my-site-backend-java.herokuapp.com/get-name");
            actions.setName(data);
           }catch(error){
       }
    }),
    setDetails:action((state,contactMe)=>{
        state.contact=contactMe;
    }),
    postContact: thunk( async (actions,entity)=>{
try{
     const {data}= await axios.post("https://my-site-backend-java.herokuapp.com/post-comment",entity);
actions.setDetails(data);
    }catch(error){
}

    })

}
