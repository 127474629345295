import { Button, Col, Container, Row } from "reactstrap";
import img from "../assets/4.png";
import PublicIcon from "@mui/icons-material/Public";
import SchoolIcon from "@mui/icons-material/School";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { ProgressBar } from "react-bootstrap";
import { useState } from "react";
import { Helmet } from "react-helmet";
export const About = () => {
  const [click, setClick] = useState(false);
  const [clicky, setClicky] = useState(false);
  // ★★★
  return (
    <Container className="p-4">
      <Helmet>
        <title>Hi, Welcome to my site.</title>
        <meta name="description" content="My Site" />
        <meta
          name="keywords"
          content="Sanskar, sanskar, sanskarbajaj, sanskar bajaj, sanskaar, bajajsanskar, Sanskar bajaj, Sanskar Bajaj, SANSKAR, SANSKAR BAJAJ, SANSKARBAJAJ, BAJAJSANSKAR, BAJAJ SANSKAR, sanskar's site, sanskar website, sanskar bajaj website, sanskar site, sanskarsite, sanskar folio, sanskarbajaj portfolio, sanskar bajaj folio, sanskar bajaj website, sanskar bajaj site, sanskar bajaj portfolio, sanskarbajaj.com, sanskar bajaj.com, sanskar personal website, sanskar personal folio, sanskar personal portfolio, bajaj sanskar site, bajaj sanskar website, bajajsanskar site, bajajsanskarsite, sanskarbajajsite, sanskarbajaj site "
        />
      </Helmet>
      <Row className="mt-5">
        <h4 className="text-dark monst">About</h4>
        <strong>
          <h2 className="text-dark monstb">Let me introduce myself</h2>
        </strong>
      </Row>
      <div className="text-dark mt-5">
        <Row>
          <Col lg="2"></Col>
          <Col lg="2" className="p-4">
            <img src={img} alt="in" />
          </Col>
          <Col lg="5" className="profdiv">
            <div>
              <p>
                People call me Sanskar or Beardo. I am a tech lover, who loves
                to get updated with technology. Well, I would like to experiment
                as much as I can, on food and my life. My body is made up of
                HTML,CSS,JAVASCRIPT,JAVA which is literally me! This defines me
                as a Full Stack Developer (Front End, Back End, Whatever...
                anything related to technology count me in.)
              </p>
              <p>
                Oh yeah, I am a highly motivated, passionate hard-core developer
                with diverse experience in developing web applications, I am
                also competitive programmer, who loves to solve complex problems
                and try to solve it in the easiest way possible.
              </p>
              <p>
                I believe in Innovation, Team Spirit, Leadership, Friendship,
                Family, Trust/Faith, Education.
              </p>
            </div>
          </Col>
          <Col lg="3"></Col>
        </Row>
        <Row className="text-dark mt-5">
          <Col lg="2"></Col>
          <Col lg="3">
            <h5 className="monst profdiv underline">Profile</h5>
            <div className=" profdiv">
              <p className="para">
                <PublicIcon fontSize="small" color="primary"></PublicIcon>
                Engineer, Full Stack Developer.
              </p>
              <p className="para">
                <SchoolIcon fontSize="small" color="success"></SchoolIcon>{" "}
                Bachelors in Computer Science and Engineering with 8.3 GPA,
                Chandigarh Engineering College Landran, Mohali.
              </p>
              <p className="para">
                <HomeRepairServiceIcon
                  fontSize="small"
                  color="error"
                ></HomeRepairServiceIcon>{" "}
                1+ year Industrial Experience.
              </p>
            </div>
            <div className="profdiv mt-5">
              <h5>Full Name</h5>
              <p>
                <strong>Sanskar</strong> Bajaj
              </p>
              <h5>Birth Date</h5>
              <p>
                <strong>7-Sept-</strong>1999
              </p>
              <h5>Profession</h5>
              <p>
                <strong>Software</strong> Engineer
              </p>
              <h5>Phone</h5>
              <p>
                <strong>+91 </strong>
                <span
                  onClick={() => setClick(true)}
                  className={click ? "" : "border-dotted"}
                  style={{ cursor: "pointer" }}
                >
                  {click ? "8571010103" : "CLICK TO REVEAL"}
                </span>
              </p>
              <h5>Email</h5>
              <p>
                <span
                  onClick={() => setClicky(true)}
                  className={clicky ? "" : "border-dotted"}
                  style={{ cursor: "pointer" }}
                >
                  {clicky ? (
                    <>
                      <strong>sanskarbajaj2015</strong>
                    </>
                  ) : (
                    "CLICK TO REVEAL"
                  )}
                </span>
                @gmail.com
              </p>
            </div>
          </Col>
          <Col lg="5">
            <h5 className="monst  profdiv underline">Skills</h5>
            <div className=" profdiv">
              <p className="para">
                I am more of a Full Stack person. I have a huge passion in
                developing web applications using Java,React & MySQL, and the
                Stack I follow is (Java , SpringBoot , ReactJS ,TypeScript ,
                PostgresSql)
              </p>
              <p className="para">
                I have good knowledge in VCS and Testing as well. I can create
                Unit as well as Integration test for any appliaction. with High
                Quality of code with full of best practices.
              </p>
            </div>
            <div className='className=" profdiv mt-5 pb-3'>
              <h5 className="monst mt-2">Java,SpringBoot</h5>
              <ProgressBar
                variant="dark"
                now={85}
                label={`85%`}
                aria-label="searchLabel1"
              ></ProgressBar>
              <h5 className="monst mt-2">React.Js</h5>
              <ProgressBar
                variant="dark"
                now={89}
                label={`89%`}
                aria-label="searchLabel2"
              ></ProgressBar>
              <h5 className="monst mt-2">PostgresSql</h5>
              <ProgressBar
                variant="dark"
                now={77}
                label={`77%`}
                aria-label="searchLabel3"
              ></ProgressBar>
              <h5 className="monst mt-2">Html,Css,Javascript</h5>
              <ProgressBar
                variant="dark"
                now={89}
                label={`89%`}
                aria-label="searchLabel4"
              ></ProgressBar>
              <h5 className="monst mt-2">Git,Github</h5>
              <ProgressBar
                variant="dark"
                now={92}
                label={`92%`}
                aria-label="searchLabel5"
              ></ProgressBar>
              <h5 className="monst mt-2">Jpa,Hibernate</h5>
              <ProgressBar
                variant="dark"
                now={82}
                label={`82%`}
                aria-label="searchLabel6"
              ></ProgressBar>
            </div>
          </Col>
          <Col lg="2"></Col>
        </Row>
        <Button
          className="mt-5 mb-5 px-5 py-2 btn-hvr btn"
          onClick={() => {
            window.open(
              "https://www.linkedin.com/in/sanskar-bajaj-68b94216b/",
              "_blank"
            );
          }}
        >
          View Profile
        </Button>
      </div>
    </Container>
  );
};
