import "bootstrap/dist/css/bootstrap.css";

import "./App.scss";
import { Footer } from "./Components/Footer";
import { About } from "./Pages/About";
import { Anonymous } from "./Pages/Anonymous";
import { ContactMe } from "./Pages/ContactMe";
import { Experience } from "./Pages/Experience";
import { HomePage } from "./Pages/HomePage";
import { Technologies } from "./Pages/Technologies";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Hi, Welcome to my site.</title>
        <meta name="description" content="My Site" />
        <meta
          name="keywords"
          content="Sanskar, sanskar, sanskarbajaj, sanskar bajaj, sanskaar, bajajsanskar, Sanskar bajaj, Sanskar Bajaj, SANSKAR, SANSKAR BAJAJ, SANSKARBAJAJ, BAJAJSANSKAR, BAJAJ SANSKAR, sanskar's site, sanskar website, sanskar bajaj website, sanskar site, sanskarsite, sanskar folio, sanskarbajaj portfolio, sanskar bajaj folio, sanskar bajaj website, sanskar bajaj site, sanskar bajaj portfolio, sanskarbajaj.com, sanskar bajaj.com, sanskar personal website, sanskar personal folio, sanskar personal portfolio, bajaj sanskar site, bajaj sanskar website, bajajsanskar site, bajajsanskarsite, sanskarbajajsite, sanskarbajaj site "
        />
      </Helmet>
      <div className="fixed-bg row text-dark ">
        <div className="fixed-bg row text-light ">
          <HomePage></HomePage>
        </div>
        <div className="fixed-bg2 row text-light ">
          <About></About>
        </div>
        <div className="fixed-bg3 row text-light ">
          <Anonymous></Anonymous>
        </div>
        <div className="fixed-bg4">
          <Experience></Experience>
        </div>
        <div className="fixed-bg5">
          <Technologies />
        </div>
        <div className="text-light">
          <ContactMe></ContactMe>
        </div>
        <div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}

export default App;
